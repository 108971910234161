import React, { Component } from 'react'
import { graphql } from 'gatsby'
import FAQItem from '../FAQItem/index'
import './style.scss'

export default class FAQ extends Component {
  constructor(props) {
    super(props)
    this.subject = props.title
    this.questions = props.questions.edges

    // map an array of all the categories used by the questions
    const usedCategories = this.questions.map(
      item => item.node.frontmatter.faq_category
    )

    // filter the categories down to only those used by the questions
    this.categories = props.categories.edges.filter(
      cat => usedCategories.indexOf(cat.node.title) > -1
    )

    this.state = {
      currentCat: this.categories[0],
    }
  }

  render() {
    return (
      <section className="bg-offwhite py-3 py-sm-5  px-lg-0">
        <div className="container ">
          <div className="row">
            <div
              className={`container mb-sm-3 ${
                this.categories.length < 2 ? 'col-md-8 offset-sm-2' : 'pl-sm-5'
              }`}
            >
              <h6>Frequently asked questions</h6>
              <h2>FAQ's about the {this.subject}</h2>
            </div>
          </div>
          <div className="row align-items-start px-1">
            {this.categories.length > 1 && (
              <div className="col-md-4 px-0 pr-md-2">
                {this.categories.map(cat => (
                  <button
                    key={cat.node.id}
                    className={`
                  border-0 bg-white
                  d-flex
                  align-items-center
                  c-faq-category
                  
                  w-100 text-left mb-2 py-3 px-2 ${cat ===
                    this.state.currentCat && 'shadow'}`}
                    onClick={() => {
                      this.setState({ currentCat: cat })
                    }}
                  >
                    <img
                      className="c-faq-category__icon mr-2"
                      width="30"
                      src={cat.node.icon.childImageSharp.original.src}
                      alt={cat.node.title}
                    />
                    <div className="c-faq-category__text pl-3">
                      <div className="c-faq-category__title">
                        {cat.node.title}
                      </div>
                      <div className="c-faq-category__description">
                        {cat.node.description}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            )}
            <div
              className={`bg-white col-md-8 pl-5 pr-3 py-3 ${this.categories
                .length < 2 && 'offset-md-2'}`}
            >
              <div className="position-relative">
                <img
                  className="position-absolute c-faq-category-icon"
                  width="30"
                  src={
                    this.state.currentCat.node.icon.childImageSharp.original.src
                  }
                  alt={this.state.currentCat.node.title}
                />
                <h3 className="mb-0">{this.state.currentCat.node.title}</h3>
                <div className="text-lighter">
                  {this.state.currentCat.node.description}
                </div>
              </div>
              {/* faq questions below */}
              <div>
                {this.questions
                  .filter(
                    faqItem =>
                      faqItem.node.frontmatter.faq_category ===
                      this.state.currentCat.node.title
                  )
                  .map((faqItem, index) => (
                    <FAQItem
                      key={faqItem.node.id}
                      first={index === 0}
                      data={faqItem}
                    />
                  ))}
              </div>
            </div>
          </div>{' '}
        </div>
      </section>
    )
  }
}

export const FAQQuery = graphql`
  fragment FAQ on MarkdownRemarkEdge {
    node {
      id
      frontmatter {
        title
        faq_category
      }
      html
    }
  }
`
