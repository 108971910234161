import { ReactComponent as MoreIcon } from '../../gfx/more-arrow.svg'

import { Link } from 'gatsby'

import React from 'react'

import './style.scss'

const ProductCard = ({ product, full, wide, icon }) => {
  return (
    <Link className="d-flex w-100" to={product.fields.slug}>
      <article
        className={`c-product-card c-product-card--${full &&
          'full'} c-product-card--${wide && 'wide'} position-relative w-100`}
        style={{ backgroundColor: product.frontmatter.color }}
      >
        <header className="px-2 px-sm-4 pt-2 pt-sm-4 c-product-card__header">
          {(!full || icon) && <MoreIcon className="mb-2 d-none d-sm-block" />}
          <div className="c-mini-title mb-2 text-white">
            {product.frontmatter.targetMarket}
          </div>
          <h2 className="c-product-card__title text-white">
            {product.frontmatter.title}
          </h2>
        </header>

        <div className="c-product-card__image-wrap">
          <img
            src={product.frontmatter.teaserImage.childImageSharp ? product.frontmatter.teaserImage.childImageSharp.original.src: product.frontmatter.teaserImage.publicURL}
            alt={product.frontmatter.title}
            className="c-product-card__image"
          />
        </div>
      </article>
    </Link>
  )
}

export default ProductCard
