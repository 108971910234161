import React from 'react'

import CTABlock from '../components/CTABlock'
import LogoList from '../components/LogoList'
import FAQ from '../components/FAQ'
import HeroSingle from '../components/HeroSingle'
import Modular from '../components/Modular'
import ContactCTA from '../components/ContactCTA'
import RelatedProducts from '../components/RelatedProducts'
import Layout from '../components/Layout'
import MassiveTitle from '../components/MassiveTitle'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import CTABanner from '../components/CTABanner'

const ProductDetail = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO path={location.pathname} frontmatter={data.page.frontmatter} />

      <article>
        <div className="position-relative" style={{ zIndex: 1 }}>
          <HeroSingle data={data.page} introTitle="Our products" />
          <CTABlock />

          <MassiveTitle modifiers={['bottom']}>Products</MassiveTitle>
        </div>

        <header className="container">
          <div className=" bg-offwhite p-2 p-sm-5 c-bg-expand-right">
            <div dangerouslySetInnerHTML={{ __html: data.page.html }} />
          </div>
        </header>
        {data.page.frontmatter.modular && (
          <Modular data={data.page.frontmatter.modular} />
        )}
        <LogoList />
        {data.faqQuestions && data.faqQuestions.edges.length && (
          <FAQ
            title={data.page.frontmatter.title}
            categories={data.faqCategories}
            questions={data.faqQuestions}
          />
        )}
      </article>
      <RelatedProducts data={data.relatedProducts} />
      <ContactCTA />
      <div className="bg-light c-footer">
        <CTABanner data={data.page.frontmatter.cta_banner} />
      </div>
    </Layout>
  )
}

export default ProductDetail

export const productQuery = graphql`
  query productQuery($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      ...SEO
      ...Modular
      ...HeroSingle
      ...ctaBanner
      html
    }

    faqQuestions: allMarkdownRemark(
      filter: {
        frontmatter: { title: { ne: "" } }
        fileAbsolutePath: { regex: "/faq/" }
        fields: { slug: { regex: $slug } }
      }
    ) {
      edges {
        ...FAQ
      }
    }

    faqCategories: allFaqCategoriesYaml {
      edges {
        node {
          id
          title
          description
          icon {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }

    relatedProducts: allMarkdownRemark(
      limit: 2
      sort: { order: ASC, fields: [frontmatter___weight] }
      filter: {
        frontmatter: { type: { eq: "product" } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            targetMarket
            color
            teaserImage {
              childImageSharp {
                original {
                  src
                }
              }
              extension
              publicURL  
            }
          }
        }
      }
    }
  }
`
