import React, { Component } from 'react'
import { graphql } from 'gatsby'

import { uniq } from 'lodash'

export default class extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    ImageSlider: false,
  }

  componentDidMount() {
    const { data } = this.props

    // make a list of all unique module names
    const moduleList = uniq(
      data.map(
        modularItem => modularItem.childMarkdownRemark.frontmatter.modular_type
      )
    )

    // loop the unique items and load the matching component into state
    moduleList.forEach(module => {
      import(`../${module}`).then(ModularComponent => {
        this.setState({ [module]: ModularComponent.default })
      })
    })
  }

  render() {
    const { data } = this.props

    return (
      <>
        {data.map(modularItem => {
          if (!modularItem) return false
          const item = modularItem.childMarkdownRemark
          const modularType = item.frontmatter.modular_type
          const ModularComponent = this.state[modularType]
          if (!ModularComponent) return false

          switch (item.frontmatter.modular_type) {
            case 'Text':
            case 'ImageSlider':
              return (
                <Container key={item.id}>
                  <ModularComponent data={item} />
                </Container>
              )
              break
            default:
              return <ModularComponent key={item.id} data={item} />
              break
          }
        })}
      </>
    )
  }
}

const Container = ({ children }) => (
  <div className="container ">
    <div className="my-1 my-sm-2 my-lg-5 px-sm-5">{children}</div>
  </div>
)

export const modularQuery = graphql`
  fragment Modular on MarkdownRemark {
    frontmatter {
      modular {
        childMarkdownRemark {
          id
          html
          frontmatter {
            modular_type
            slider_images {
              file {
                id
                childImageSharp {
                  landscape: fluid(quality: 90, maxWidth: 750, maxHeight: 512) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  portrait: fluid(quality: 90, maxWidth: 480, maxHeight: 586) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              description
            }
            # specific to annotation images
            imageWidth
            imageHeight
            altText
            annotations {
              title
              description
              descriptionPosition
              x
              y
            }
            annotatedImage {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            # end specific to annotation images
            # specific to tabbed text
            title
            introTitle
            tabs {
              childMarkdownRemark {
                id
                html
                frontmatter {
                  title
                  image {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 900) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
            # end specficic to tabbed text
            # specific to dashboard
            dashboardItems {
              title
              subTitle
              icon {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            # end specficic to dashboard
            # specific to text w image
            paragraphImage {
              childImageSharp {
                fluid(quality: 90, maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            textPosition
            # end specficic to text w image
          }
        }
      }
    }
  }
`
