import React, { Component } from 'react'

import { ReactComponent as Open } from '../../gfx/faq-open.svg'
import { ReactComponent as Close } from '../../gfx/faq-close.svg'

import './style.scss'

export default class FAQItem extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  state = {
    isOpen: false,
  }

  toggleAnswer = () => {
    this.setState(prevState => {
      return { isOpen: !prevState.isOpen }
    })
  }

  render() {
    const { data, first } = this.props
    return (
      <article className={`c-faq-item py-1 ${!first && 'border-top'}`}>
        <button
          onClick={this.toggleAnswer}
          className="c-faq-item__question text-left d-flex p-0 align-center w-100 reset border-0 bg-none font-weight-bold text-primary py-2 bg-transparent"
        >
          <span className="c-faq-item__question__label">
            {data.node.frontmatter.title}
          </span>
          <span className="c-faq-item__question__icon ml-auto pl-1">
            {this.state.isOpen ? (
              <Close className="d-block" />
            ) : (
              <Open className="d-block" />
            )}
          </span>
        </button>
        <div
          className="c-faq-item__content pb-2 text-dark"
          style={{ display: this.state.isOpen ? 'block' : 'none' }}
          dangerouslySetInnerHTML={{ __html: data.node.html }}
        />
      </article>
    )
  }
}
