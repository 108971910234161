import React from 'react'
import ProductCard from '../ProductCard/index'
import MassiveTitle from '../MassiveTitle/index'

const RelatedProducts = ({ data }) => {
  const { edges } = data
  return (
    <section className="position-relative pt-2 pt-lg-5 pb-md-5 pb-xs-3">
      <MassiveTitle>Products</MassiveTitle>
      <div className="container">
        <div className="row">
          <div className="col-12 mb-2 col-lg-4 pr-2">
            <h6>Related</h6>
            <h2>Check out our other products and services</h2>
            <p>
              Integrate the DataStories computational engine into your work
              environment. Same brilliance, consistent functionality, more
              flexibility for users with different roles and tool preferences.
            </p>
          </div>
          {edges.map(edge => (
            <div
              className="col col-sm-6 mb-2 col-lg-4 d-flex"
              key={edge.node.id}
            >
              <ProductCard product={edge.node} full icon />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default RelatedProducts
